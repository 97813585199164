@import "HiroCustom.scss"; 

@import "AdvanceTablesWidgetEpisodesStyles.scss",
  "AdvanceTablesWidgetListenersStyles.scss",
  "AsideCustomProductLabel.scss",
  "AsideCustomStyles.scss",
  "EpisodeEdit.scss",
  "ProductTopDropdownStyles.scss",
  "SubAsideCustomStyles.scss",
  "SubHeaderCustomStyles.scss",
  "PublicPagesStyles.scss",
  "ProductLogoComponent.scss",
  "coverUploaderComponent.scss";


.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
      overflow-y: auto;
      display: block;

      span {
        background: none transparent !important;
      }
  }
}

.json > pre {
  background: none $white-bg !important;

}

.example-code > .json {
  background: none $white-bg !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.product-settings-status-label {
  font-size: 14px;
  line-height: 21px;

  &-draft {
    color: $gray-text;
  }

  &-live {
    color: $green;
  }
}

.product-settings-status-container {
  position: absolute;
  top: 32px;
  right: 55px;
  display: flex;
  align-items: center;
  width: 50%;
}

.alert.product-settings-status-alert {
  margin-bottom: 0;
  padding-left: 13px;
  padding-right: 8px;
  position: absolute;
  left: 140px;
  min-width: 230px;

  &-live {
    background-color: $green-light;
    color: $green;
  }

  img {
    margin-right: 13px;
  }
}

.symbol.symbol-45.menu-link-image-container {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    right: -3px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 3px solid $white;
    top: 50%;
    transform: translateY(-50%);
  }

  &.draft::after {
    background-color: $red-light;
  }

  &.live::after {
    background-color: $green-light;
  }
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
        color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.episodes-table {
  .episode-table-label-live {
    background-color: $green-light;
    color: $green;
  }
  .episode-table-label-draft {
    color: $dark-text;
    background-color: $gray-bg;
  }
}