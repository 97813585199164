.productTopDropdownComponent {
    position: fixed;
    top: 0;
    left: 72px;
    width: 300px;
    height: 72px;
    z-index: 3;
    background: $white-bg;

    .dropdown-menu {
        padding: 0;
    }

    .dropdown-item {
        width: 100%!important;

        svg {
            margin: 0!important;
            width: auto!important;
        }
    }

    #productTopDropdownComponent-toogle-btn {
        background-color: transparent;
        border: none;
        padding: 0;
        margin: 24px 0 0 24px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $dark-text;
        width: 100%;
        text-align: left;

        &>span {
            max-width: 90%;
            overflow: hidden;
            display: inline-block;
            white-space: nowrap;
            text-overflow: ellipsis;
            vertical-align: middle;
        }
        &::after {
            color: $dark-text;
        }
    }

    &-menu {
        &-item {
            position: relative;
            padding-top: 21px!important;
            padding-bottom: 20px!important;
            width: 285px!important;

            &::after {
                content: '';
                display: block;
                width: 238px;
                border-bottom: 1px dashed #DADADA;
                position: absolute;
                bottom: 0;
                left: 27px;
            }

            &:last-child {
                &::after {
                    display: none;
                }
            }

            &-img {
                width: 35px;
                height: 35px;
                overflow: hidden;
                border-radius: 4px;
                margin-right: 17px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 100%!important;
                    height: 100%;
                    margin-right: 0!important;
                }
            }

            &-title {
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                line-height: 19px;
                color: #000000;
                max-width: 146px;
                overflow: hidden;
                display: inline-block;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-right: 20px;
            }

            &-link {
                background-color: transparent;
                border: none;
                box-shadow: none;
                margin-top: 6px;
                margin-right: 21px;
                padding: 0;
                position: absolute;
                right: 16px;
            }
        }
    }
}