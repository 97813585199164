.asideCustomProductLabel {
    display: flex;
    align-items: center;
    background-color: #000;
    padding: 5px 8px 5px 13px;
    position: absolute;
    left: 49px;
    top: 5px;
    width: max-content;
    border-radius: 6px;
    z-index: 4;

    &::before {
        content: '';
        display: block;
        background: $dark;
        transform: rotate(-45deg);
        width: 14px;
        height: 14px;
        left: -4px;
        position: absolute;
    }

    &-title {
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 16px;
        color: $white;
        margin-right: 9px;
        flex-shrink: 0;
    }

    &-count {
        height: 18px;
        background: $dark-text;
        border-radius: 4px;
        padding-left: 6px;
        padding-right: 6px;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        span {
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            line-height: 16px;
            color: $white;
        }
    }
}