// Colors

$pure-white: #FFFFFF;
$pure-black: #000000;
$hiro-blue: #3699FF;
$hiro-neutral-800: #3F4254;
$hiro-neutral-600: #7E8299;
$hiro-gray: #E4E6EF;
$hiro-gray-100: #f5f8fa;

.btn {
    &.btn-hiro {
        &-blue {
            background-color: $hiro-blue;
            border-color: $hiro-blue;
            color: $pure-white;
            padding: 15px 15px;
            font-size: 18px;
            &:hover,
            &:focus {
                background-color: darken($hiro-blue, 5%);
                border-color: darken($hiro-blue, 5%);
                color: $pure-white;
            }
        }  
    }
}
