.episodeEditPage {
    .header-page {
        border-bottom: none;
        background: $white-bg;
        padding-left: 40px;
        padding-right: 40px;
        height: 88px;

        .back-to-prev-page {
            width: 24px;
            height: 24px;
            margin-right: 32px;
        }

        &-title {
            align-items: center;
        }
    }

    .episode-edit {
        background-color: initial;
        border-top: none;
        align-items: center;
    }

    .upload-page {
        background-color: initial;
    }

    .upload-produc-title-icon {
        width: 48px;
        height: 48px;
        margin-right: 16px;
        border-radius: 8px;

        .cover-image {
            width: 100%;
            height: 100%;
        }
    }

    .upload-produc-title h4 {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $dark-text;
        margin-bottom: 2px !important;
    }

    .episode-name span {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: $gray-text;
    }

    .episode-name {
        .header-page-title-label {
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: $dark-text;
            margin-right: 40px;
        }

        .header-page-title-divider {
            width: 1px;
            height: 56px;
            background-color: #EBEDF3;
            margin-right: 40px;
        }
    }

    .card {        
        .product-settings-status-container {
            position: absolute;
            position: absolute;
            width: max-content;
            flex-direction: column;
            right: 45px;
            top: 31px;

            &-title {
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                text-align: right;
                color: $gray-text;
            }

            &-dropzone-audio {
                margin-bottom: 40px;
            }

            &-input {
                margin-bottom: 40px;

                .custom-select-wrap {
                    &-label {
                        background: #E1F0FF;
                        border-radius: 99px;
                        padding: 4px 12px;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 15px;
                        color: $primary;
                    }
                }

                .form-label, .section-title {
                    display: block;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    color: $dark-text;
                }

                .section-title {
                    display: flex;

                    &-sample-label {
                        background: #E1F0FF;
                        border-radius: 99px;
                        display: inline-flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        padding: 4px 12px;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 13px;
                        line-height: 15px;
                        color: $primary;
                        margin-left: 17px;
                    }
                }

                input {
                    width: 100%;
                    max-width: 100%;
                    padding-left: 16px;
                    padding-right: 16px;
                }

                .react-tagsinput {
                    background: $gray-bg;
                    border-radius: 8px;
                    border: none;
                    padding: 7px 16px;

                    &-input {
                        width: initial;
                        max-width: initial;
                        margin-bottom: 0;
                        margin-top: 0;
                        height: 25px;
                        padding: 0;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 11px;
                        line-height: 16px;
                        color: $dark-text;
                    }

                    &-tag {
                        background: $white-bg;
                        border-radius: 4px;
                        border: none;
                        margin-bottom: 0;
                        margin-right: 10px;
                        padding: 4px 10px 4px 26px;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 16px;
                        color: $dark-text;
                        position: relative;

                        .react-tagsinput-remove {
                            position: absolute;
                            left: 0;
                            top: 8px;
                            width: 12px;
                            height: 12px;

                            &::before {
                                content: '';
                                display: inline-block;
                                width: 12px;
                                height: 12px;
                                background-image: URL("data:image/svg+xml,%3Csvg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.35355 3.14645C9.54882 3.34171 9.54882 3.65829 9.35355 3.85355L3.35355 9.85355C3.15829 10.0488 2.84171 10.0488 2.64645 9.85355C2.45118 9.65829 2.45118 9.34171 2.64645 9.14645L8.64645 3.14645C8.84171 2.95118 9.15829 2.95118 9.35355 3.14645Z' fill='%237E8299'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.64645 3.14645C2.84171 2.95118 3.15829 2.95118 3.35355 3.14645L9.35355 9.14645C9.54882 9.34171 9.54882 9.65829 9.35355 9.85355C9.15829 10.0488 8.84171 10.0488 8.64645 9.85355L2.64645 3.85355C2.45118 3.65829 2.45118 3.34171 2.64645 3.14645Z' fill='%237E8299'/%3E%3C/svg%3E%0A");
                            }
                        }
                    }
                }

                .input-sub-description {
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    color: $gray;
                }

                textarea {
                    width: 100%;
                    max-width: 100%;
                    padding-left: 16px;
                    padding-right: 16px;
                    margin-bottom: 14px;
                }

                .sub-title {
                    width: 100%;
                    max-width: 490px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: inherit;
                    color: $gray-600;
                }

                .checkbox-group {
                    margin-bottom: 0;

                    label {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 13px;
                        line-height: 19px;
                        color: $dark-text;
                    }

                    input {
                        width: 18px;
                        height: 18px;
                        background: #EBEDF3;
                        border-radius: 4px;
                        border: 0;
                        padding: 0;
                        box-shadow: none;
                        margin-right: 8px;
                        margin-bottom: 0;
                        vertical-align: top;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;

                        &:hover {
                            cursor: pointer;
                        }

                        &:checked {
                            background-color: #009ef7;
                            background-size: 60% 60%;
                            background-repeat: no-repeat;
                            background-position: 50%;
                            background-image: URL("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='11' fill='none'%3E%3Cpath d='M11.043 1.029a.792.792 0 111.206 1.025l-6.73 7.917a.792.792 0 01-1.128.079L.828 6.883A.792.792 0 111.88 5.7l2.958 2.629 6.205-7.3z' fill='%23fff'/%3E%3C/svg%3E");
                        }
                    }
                }

                select {
                    background-color: $field-bg;
                    width: auto;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    background-image: URL("data:image/svg+xml;utf8,<svg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M4.5 6.5L7.5 9.5L10.5 6.5' stroke='black' stroke-linecap='square'/></svg>");
                    background-position: calc(100% - 16px) 14px;
                    background-size: 15px;
                    background-repeat: no-repeat;
                    padding-right: 40px;
                }

                .form-custom-datepicker {
                    width: max-content;
                    max-width: initial;
                    border-color: $gray-bg;
                    color: $dark-text;
                    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;

                    input {
                        padding-left: 16px;
                        padding-right: 16px;
                        background-color: $gray-bg;
                        font-size: 1.08rem;
                        line-height: 1.5;
                        border: none;
                        border-radius: 0.42rem;
                    }
                }

                .dropzone-audio {
                    &-row {
                        display: flex;
                        align-items: center;
                        margin-bottom: 8px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }

                    &-duration {
                        background: #E1F0FF;
                        border-radius: 99px;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 11px;
                        line-height: 15px;
                        color: $primary;
                        padding: 4px 12px;
                        margin-right: 7px;
                    }

                    .btn-remove {
                        width: 24px;
                        height: 24px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 0;
                        border: none;
                        background: $white-bg;
                        border-radius: 50%;
                        overflow: hidden;
                        box-shadow: 0px 4px 16px rgba(63, 66, 84, 0.08);
                        margin-left: 16px;
                    }

                    .dropzone-msg-desc {
                        font-style: normal;
                        font-weight: normal;
                        font-size: inherit;
                        line-height: 18px;
                        color: $dark-text;
                    }
                }
            }
        }

        &-title {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
            color: $dark-text;
            margin-bottom: 8px;
        }

        &-description {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 21px;
            color: $gray-text;
            width: 100%;
            max-width: 506px;
            margin-bottom: 40px;
        }
    }
}

.upload-page .upload-produc-title-icon .cover-image {
    width: 100%;
    height: 100%;
}