.advanceTablesWidgetListenersComponent {
    &-table-controls-wrap {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .MuiPaper-root {
            width: 438px !important;
        }

        &-buttons {
            display: flex;
        }

        .btn {
            display: inline-flex;
            align-items: center;

            &:disabled {
                cursor: default;
            }
        }

        .remove-episode-button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 43px;
            height: 43px;
            border: 2px solid $gray;
            background-color: transparent;
            border-radius: 8px;
            box-shadow: none;
            opacity: 1;

            &:hover {
                cursor: pointer;
            }

            &:disabled {
                opacity: 0.6;

                &:hover {
                    cursor: default;
                }
            }
        }
    }
}

.listener-table-cell-label {
    background: $gray-bg;
    border-radius: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.03em;
    color: $dark-text;
    padding: 7px 10px;
    white-space: nowrap;
}