.subHeaderCustomComponent {
    background: $white-bg;
    top: 72px;
    border-top: 1px solid $gray-bg;
    position: fixed;
    left: 296px;
    height: 72px;
    width: calc(100% - 296px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 22px;
    padding-right: 58px;
    z-index: 99;

    &-left {
        display: flex;
        align-items: center;
    }

    &-right {
        display: flex;
        align-items: center;
    }

    &-title {
        display: inline-flex;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        height: 24px;
        margin-bottom: 0;
        color: $dark-text;
        margin-right: 31px;
    }

    &-line {
        display: block;
        height: 44px;
        width: 1px;
        background-color: #E5E5E5;
        margin-right: 21px;
    }

    &-sub-title {
        margin-bottom: 0;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        height: 20px;
        color: $gray-text;

        &-right {
            color: #000;
        }
    }

    &-controls {
        justify-self: flex-end;
    }
}