.subAsideCustomComponent {
    position: fixed;
    top: 72px;
    left: 72px;
    width: 224px;
    height: 100vh;
    background-color: $white;
    border-right: 1px solid #EFF2F5;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    padding-top: 27px;
    z-index: 2;

    &.is-disabled {
        opacity: 1!important;
        pointer-events: none;
        
        * {
            opacity: .6;
        }
    }

    &::before {
        position: absolute;
        content: '';
        display: block;
        top: 0;
        left: 24px;
        height: 1px;
        width: 100%;
        background-color: $gray-bg;
    }

    &-title {
        display: inline-block;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 16px;
        color: $gray-text;
        margin-bottom: 18px;
    }

    &-link {
        display: flex;
        padding-top: 12px;
        padding-bottom: 12px;
        margin-bottom: 11px;
        margin-right: 12px;
        padding-left: 12px;

        &:last-child {
            margin-bottom: 0;
        }

        &-img {
            width: 24px;
            height: 24px;
            margin-right: 8px;
            text-align: center;

            svg {
                display: block;
            }
        }

        &-title {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            color: $dark-text;
            transition: color 0.3s ease-in-out;
        }

        &:hover {
            .subAsideCustomComponent-link-title {
                color: $primary;
            }

            .subAsideCustomComponent-link-title {
                color: $primary;
            }

            .subAsideCustomComponent-link-img {
                svg path {
                    fill: $primary;
                }

                .settings-icon {
                    path:first-child {
                        fill: #FFF;
                    }
                }
            }
        }

        &.active {
            background: $gray-bg;
            border-radius: 8px;

            .subAsideCustomComponent-link-title {
                color: $primary;
            }

            .subAsideCustomComponent-link-img {
                svg path {
                    fill: $primary;
                }

                .settings-icon {
                    path:first-child {
                        fill: $gray-bg;
                    }
                }
            }
        }
    }
}