.cover-image {
    overflow: hidden;
    border-radius: 6px;
    
    img {
        width: 100%!important;
        height: 100%!important;
        max-width: none!important;
        object-fit: cover;
    }
}
.productLogoComponent {
    $coverColor1: #3F4254;
    $coverColor2: #F64E60;
    $coverColor3: #EF7C35;
    $coverColor4: #FFA800;
    $coverColor5: #F9D247;
    $coverColor6: #B8E24A;
    $coverColor7: #08C98F;
    $coverColor8: #99DAFF;
    $coverColor9: #3699FF;
    $coverColor10: #605DF0;
    $coverColor11: #8950FC;
    $coverColor12: #EE83F8;
    $coverColor13: #F178B6;
    $coverColor14: #607893;

    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        max-width: 45%;
        max-height: 45%;

        & path {
            fill: #FFF;
        }
    }

    &.color-1 {
        background-color: $coverColor1;
    }

    &.color-2 {
        background-color: $coverColor2;
    }

    &.color-3 {
        background-color: $coverColor3;
    }

    &.color-4 {
        background-color: $coverColor4;
    }

    &.color-5 {
        background-color: $coverColor5;
    }

    &.color-6 {
        background-color: $coverColor6;
    }

    &.color-7 {
        background-color: $coverColor7;
    }

    &.color-8 {
        background-color: $coverColor8;
    }

    &.color-9 {
        background-color: $coverColor9;
    }

    &.color-10 {
        background-color: $coverColor10;
    }

    &.color-11 {
        background-color: $coverColor11;
    }

    &.color-12 {
        background-color: $coverColor12;
    }

    &.color-13 {
        background-color: $coverColor13;
    }

    &.color-14 {
        background-color: $coverColor14;
    }
}