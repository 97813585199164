.coverUploaderComponent {
    $coverColor1: #3F4254;
    $coverColor2: #F64E60;
    $coverColor3: #EF7C35;
    $coverColor4: #FFA800;
    $coverColor5: #F9D247;
    $coverColor6: #B8E24A;
    $coverColor7: #08C98F;
    $coverColor8: #99DAFF;
    $coverColor9: #3699FF;
    $coverColor10: #605DF0;
    $coverColor11: #8950FC;
    $coverColor12: #EE83F8;
    $coverColor13: #F178B6;
    $coverColor14: #607893;

    position: relative;

    .dropdown-menu {
        padding: 0 !important;
    }

    &-cover {
        width: 116px;
        height: 116px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        &-container {
            width: 100%;
            height: 100%;
            border-radius: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;

            svg {
                height: 56px;

                & path {
                    fill: #FFF;
                }
            }

            img {
                width: 100%;
                height: 100%;
            }

            &.color-1 {
                background-color: $coverColor1;
            }
    
            &.color-2 {
                background-color: $coverColor2;
            }
    
            &.color-3 {
                background-color: $coverColor3;
            }
    
            &.color-4 {
                background-color: $coverColor4;
            }
    
            &.color-5 {
                background-color: $coverColor5;
            }
    
            &.color-6 {
                background-color: $coverColor6;
            }
    
            &.color-7 {
                background-color: $coverColor7;
            }
    
            &.color-8 {
                background-color: $coverColor8;
            }
    
            &.color-9 {
                background-color: $coverColor9;
            }
    
            &.color-10 {
                background-color: $coverColor10;
            }
    
            &.color-11 {
                background-color: $coverColor11;
            }
    
            &.color-12 {
                background-color: $coverColor12;
            }
    
            &.color-13 {
                background-color: $coverColor13;
            }
    
            &.color-14 {
                background-color: $coverColor14;
            }
        }

        &-edit-btn {
            width: 40px;
            height: 40px !important;
            border-radius: 50% !important;
            background-color: #FFF !important;
            box-shadow: 0px 4px 16px rgba(63, 66, 84, 0.08) !important;
            border: none !important;
            position: absolute;
            right: -10px;
            bottom: -10px;
            padding: 0 !important;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 16px;
            }

            &::after {
                display: none !important;
            }
        }

        &-remove-btn {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #FFF;
            box-shadow: 0px 4px 16px rgba(63, 66, 84, 0.08);
            border: none;
            position: absolute;
            right: -10px;
            top: -10px;
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 16px;
            }
        }

        .edit {
            .asideCustomProductLabel {
                right: -76px;
                left: initial;
                top: initial;
                bottom: -1px;
            }
        }

        .remove {
            .asideCustomProductLabel {
                right: -90px;
                left: initial;
                top: -5px;
                bottom: initial;
            }
        }
    }

    &-edit {
        position: relative;
        z-index: 11;
        padding: 32px;
        display: flex;

        &-column {
            width: 266px;
            margin-right: 16px;

            &:last-child {
                margin-right: 0;
            }

            &-header {
                display: flex;
                align-items: center;
                margin-bottom: 16px;
                min-height: 23px;

                &-label {
                    margin-left: 24px;
                    background: #FFF4DE;
                    border-radius: 4px;
                    padding: 7px 8px 2px 8px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 11px;
                    line-height: 1;
                    color: #FFA800;
                    display: flex;
                    height: 23px;
                }
            }

            &-header-text {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                color: #3F4254;
                display: block;
            }

            .dropzone.needsclick {
                height: 306px !important;

                > div {
                    height: 306px !important;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .content-dropzone {
                margin-bottom: 0 !important;
            }

            &-cover-control {
                width: 100%;
                border: 1px solid #E4E6EF;
                border-radius: 8px;
                padding: 25px 0 0 0;

                .colors-control {
                    display: flex;
                    flex-wrap: wrap;
                    padding-left: 25px;
                    padding-right: 25px;
                    padding-bottom: 17px;
                    border-bottom: 1px solid #E4E6EF;

                    &-item {
                        width: 24px;
                        height: 24px;
                        border-radius: 4px;
                        margin-right: 7px;
                        margin-bottom: 7px;
                        flex-shrink: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &:nth-child(7n) {
                            margin-right: 0;
                        }

                        &:hover {
                            cursor: pointer;
                            filter: saturate(2.2);
                        }
                    }
                }

                .icons-control {
                    display: flex;
                    flex-wrap: wrap;
                    padding: 25px 25px 0 25px;

                    &-item {
                        width: 40px;
                        height: 40px;
                        border-radius: 4px;

                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 18px;
                        margin-bottom: 18px;

                        &:nth-child(4n) {
                            margin-right: 0;
                        }

                        svg {
                            height: 22px;
                        }

                        &:hover {
                            background-color: #3699FF;
                            cursor: pointer;

                            svg {
                                & path {
                                    fill: #FFF;
                                }
                            }
                        }

                        &.active {
                            background-color: #3699FF;

                            svg {
                                & path {
                                    fill: #FFF;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}