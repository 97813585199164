.asideCustomComponent {
    position: fixed;
    top: 0;
    left: 0;
    width: 72px;
    height: 100vh;
    background: $gray-bg;
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    align-items: center;
    z-index: 3;

    &-main-logo {
        margin-bottom: 43px;
    }

    &-home,
    &-products {
        margin-bottom: 26px;
    }

    &-home,
    &-sms,
    &-products {
        position: relative;
        width: 100%;
        text-align: center;

        &::before {
            content: "";
            position: absolute;
            display: block;
            width: 5px;
            height: 24px;
            left: 5px;
            top: 0;
            background-color: #16A9F5;
            border-radius: 10px;
            opacity: 0;
            transition: all .1s;
        }
        svg {
            rect,
            path {
                transition: all .1s;
            }
        }
        &:hover,
        &.active {
            svg {
                rect,
                path {
                    fill: $primary-hover;
                }
            }
        }
        &.active {
            &::before {
                opacity: 1;
            }
        }
    }

    &-products {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 24px;
    }

    &-sms {
        margin-bottom: 64px;
    }

    &-create-product {
        margin-bottom: 24px;

        &:hover {
            svg rect {
                fill: $primary-hover;
            }

            svg path {
                stroke: #fff;
            }
        }
    }

    &-products-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        &-item {
            position: relative;
            width: 100%;
            margin-bottom: 24px;

            .asideCustomProductLabel {
                opacity: 0;
                transition: opacity 0.15s ease;
                left: 100%;
            }

            .cover-image {
                width: 100%;
                height: 100%;
            }

            &:last-child {
                margin-bottom: 0;
            }
            
            a {
                position: relative;
                display: block;
                width: 40px;
                height: 40px;
                border-radius: 6px;
                border: none;
                transform: none;
                margin: auto;
                transition: box-shadow .2s;

                &::after {
                    content: "";
                    position: absolute;
                    right: -5px;
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    border: 3px solid #fff;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &:hover {
                    box-shadow: 0 0 0 4px $gray;

                    &~ .asideCustomProductLabel {
                        opacity: 1;
                    }
                }
            }

            &.live {
                a {
                    &::after {
                        background-color: $green;
                    }
                }
            }

            &.draft {
                a {
                    &::after {
                        background-color: $red;
                    }   
                }
            }

            &.over-limit {
                a {
                    &::after {
                        background-color: $yellow;
                    }   
                }
            }

            img {
                width: 100%;
                height: 100%;
            }

            &::before {
                content: '';
                position: absolute;
                display: block;
                width: 5px;
                height: 40px;
                left: 5px;
                top: 0;
                background-color: $primary;
                border-radius: 10px;
                transition: all .1s;
                opacity: 0;
            }

            &.current {
                &::before {
                    opacity: 1;
                }
                a {
                    box-shadow: 0 0 0 4px $gray;
                }
            }
        }
    }
}